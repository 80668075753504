<template>
  <div
      ref="root"
      class="btn-support"
      :class="{'show': true}"
  >
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        class="btn-icon"
        @click="test"
    >
      <icon icon="life-ring"/>
    </b-button>


    <!-- modal ticket -->
    <modal-ticket
        :ticket.sync="currentTicket"
        :title="ticketModalTitle"
        :isOpen.sync="ticketModalShow"
        @submitValidated="submitValidatedTicketLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinTickets } from '../../views/support/tickets/mixinTickets'
import { capitalize } from '../../utils/filter'

import api from '@/libs/axios'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import regeneratorRuntime from "regenerator-runtime"

import Vue from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'

Vue.use(VueHtml2Canvas)

export default {
  directives: {
    Ripple,
  },
  components: {},
  mixins: [mixinTickets],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const apiURL = ref(api.defaults.baseURL + 'documents')
    const root = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      apiURL,
      root,

      // Computed

      // Methods
      // test
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    test () {
      let node = document.getElementById('app')
      if (document.getElementById('body').className.includes('modal-open')) {
        node = document.getElementById('body')
      }



      this.$html2canvas(node, { type: 'dataURL' })
          .then(dataUrl => {
            let xhr = new XMLHttpRequest()
            let formData = new FormData()
            formData.append('type', 'ticket')
            formData.append('file', this.dataURLtoFile(dataUrl))

            this.makeRequest(formData)
                .then(response => {
                  // console.log(response)
                  this.ticketModalShow = true
                  this.currentTicket.files.push(response)

                  this.currentTicket.module = capitalize(this.$route.name)
                  this.currentTicket.page = this.$route.fullPath
                })
          })

    },
    dataURLtoFile (dataURL) {
      let arr = dataURL.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], 'Screenshot.png', { type: mime })
    },
    makeRequest (formData) {
      let apiURL = this.apiURL

      return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest()
        xhr.open('POST', apiURL, true)
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(JSON.parse(xhr.response))
          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            })
          }
        }
        xhr.onerror = function () {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          })
        }
        xhr.send(formData)
      })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.btn-support {
  position: fixed;
  bottom: 5%;
  right: 30px;
  z-index: 9999;

  opacity: 0;
  transition: all .5s ease;

  &.show {
    opacity: 1;
  }
}
</style>